<template>
  <div class="iot-person public-page-style">
    <div class="iot-person-info public-shadow">
      <el-form ref="platformRef" :model="platform" label-width="80px">
        <h3 style="margin: 0 0 40px 0px">平台中性化</h3>
        <el-form-item label="平台名称" prop="platformName">
          <el-input v-model="platform.platformName"></el-input>
        </el-form-item>
        <el-form-item label="访问后缀" prop="platformPath">
          <el-input v-model="platform.platformPath"></el-input>
        </el-form-item>
        <el-form-item label="访问网址" prop="platformPath">
          <div>{{addressUrl}}#/iot-{{platform.platformPath}}</div>
        </el-form-item>
        <el-form-item label="平台图标" prop="platformLogo">
          
          <el-form ref="imgUpload">
            <el-upload
              class="avatar-uploader"
              :action="platformImgUpload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              
              :before-upload="beforeAvatarUpload"
              :data="{
                typeName: 'platform',
              }"
            >
              <img
                v-if="platform.platformLogo"
                :src="
                  constant.OSS_URL +
                  constant.PLATFORM_IMG +
                  platform.platformLogo
                "
                class="avatar"
              />
              <img
                class="avatar"
                v-else-if="imgUrl"
                :src="constant.OSS_URL + constant.PLATFORM_IMG + imgUrl"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form>
        </el-form-item>
        <el-button
          class="iot-baseinfo-submit"
          type="primary"
          plain
          size="mini"
          @click="submitPlatformForm"
          >修改</el-button
        >
        <el-input
          v-model="platform.platformId"
          type="hidden"
          style="display: none"
        ></el-input>
      </el-form>
    </div>
  </div>
</template>

<script>
import { baseUrl,addressUrl } from "../util/global";
import { OSS_URL, PLATFORM_IMG } from "../util/constant";
let that;
export default {
  name: "Platform",
  props: {
    msg: String,
  },
  data() {
    return {
      addressUrl:addressUrl,
      constant: {
        OSS_URL,
        PLATFORM_IMG,
      },
    
      user: {
        userId: "",
        mobile: "",
      },
      platform: {
        platformId: "",
        platformName: "",
        platformPath: "",
        platformLogo: "",
      },
      platformImgUpload: baseUrl + "img",
      imgUrl: "",
    };
  },
  methods: {
    handleAvatarSuccess(res) {
      if (200 == res.code) {
        that.imgUrl = res.data;
        that.$set(that.platform, "platformLogo", that.imgUrl);
      }
    },
    beforeAvatarUpload(file) {
      that.$refs["imgUpload"].validate((valid) => {
        if (valid) {
          const isImg =
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png" ||
            file.type === "image/gif";
          if (!isImg) {
            this.$message.error("图片格式仅限jpeg，jpg，png，gif!");
          }
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            this.$message.error("图片大小不得超过2MB!");
          }
          return isImg && isLt2M;
        } else {
          this.$message.error("请查看表单信息是否有遗漏！");
        }
      });
    },
   
    getPlatform() {
      that.$api.platform
        .getPlatform(that.$store.state.userId)
        .then((response) => {
          if (response.code === 200) {
            that.platform = response.data;
          }
        });
    },
  
    submitPlatformForm() {
      that.$refs["platformRef"].validate((valid) => {
        if (valid) {
          that.$api.platform.editPlatform(that.platform).then((response) => {
            if (response.code == 200) {
              location.reload();
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getPlatform();
            }
          });
        }
      });
    },
  },

  beforeMount() {
    that = this;
  },
  mounted() {
    that.getPlatform();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.public-page-style{
  height: calc(100% - 50px);
}
h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}
.iot-person {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.iot-person-info {
  width: 38%;
  height: 65%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.iot-person-info >>> .el-form {
  width: 80%;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader >>> .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
  display: block;
}
.iot-baseinfo-submit {
  margin-left: calc(50% - 40px);
}
</style>
